body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradientBG 15s ease infinite;
    min-height: 100vh;
  }
  
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .dashboard-container {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
  
  .stat-card {
    transition: transform 0.3s ease-in-out;
  }
  
  .stat-card:hover {
    transform: translateY(-5px);
  }
  
  .map-container {
    border-radius: 15px;
    overflow: hidden;
  }
  
  .search-container {
    margin-bottom: 20px;
  }
  
  .table-container {
    border-radius: 15px;
    overflow: hidden;
  }